import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { identifierModuleUrl } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class PromoService {

  constructor(public afs: AngularFirestore) { }

  async getAllPromos() {
    return await this.afs.collection('promo');
  }


  deletePromo(id: string) {
    return this.afs.collection('promo').doc(id).delete()
  }

  addPromo(promo: string) {
    let id = this.afs.createId(); 
    return this.afs.collection('promo').doc(id).set({id: id, label: promo, value: promo})
  }

}
