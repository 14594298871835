import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  prod: any;
  singleProduct: any;

  // UPDATE
  name = '';
  subname = '';
  availability = '';
  image = '';
  description = '';
  details = '';
  factory_price: number;
  retail_price: number;
  wholesale_price: number;
  promo: [''];

  public productId: string;
  public picture: string;
  public url: string;

  uploadPercent: any;
  uploadFlag: any;

  constructor( public productService: ProductsService, public afa: AngularFireAuth,
              public afs: AngularFirestore, public afstorage: AngularFireStorage              ) { }

  ngOnInit() {
    this.getAllProduct();
  }

  getAllProduct() {
    this.productService.getAllProducts().then((res) => {
      res.valueChanges().subscribe( ref => {
        this.prod = ref;
        // console.log(this.prod)
      })
    }) 
  }

  getSingleProduct(id: string) {
    this.productService.getSingleProduct(id).valueChanges().subscribe( ref => {
      this.singleProduct = ref;
      // console.log(this.singleProduct)
    })
  }

  saveChanges(id: string) {
    this.productService.updateProduct(id, this.factory_price, this.retail_price, this.wholesale_price).then(() => {
        this.factory_price = 0, this.retail_price = 0, this.wholesale_price = 0
      })
  }

  async deleteProduct(id: string) {
    // console.log(id)
   await this.productService.deleteProduct(id).delete();
  }

  addPromo() {
    this.promo.push('')
   }

  addProduct() {
    const id = this.afs.createId();
    this.productId = id;
    // console.log(this.image, this.productId)
    this.afs.collection('products').doc(id).set({
      id: id,
      name: this.name,
      subname: this.subname,
      availability: this.availability,
      image: this.image,
      description: this.description,
      details: this.details,
      factory_price: this.factory_price,
      retail_price: this.retail_price,
      wholesale_price: this.wholesale_price,
    }).then(() => {
      this.name = '', this.subname = '', this.availability = '', this.image = '', this.description = '',
      this.details = '', this.factory_price = 0, this.retail_price = 0, this.wholesale_price = 0
    });
  }


  changeListener(event: any, productId: string): void {
    // this.presentLoadingWithOptions();
  this.uploadFlag = true;
      // console.log(Date.now());
      const file = event.target.files[0];
      const filePath = 'productImages/' + Date.now() + '.jpg';
      const fileRef = this.afstorage.storage.ref(filePath);
      const task = fileRef.put(file);
      this.uploadPercent = task.snapshot.state;
       //  percentageChanges();
      // // console.log(this.uploadPercent);
      task.then((snap) => {
        snap.ref.getDownloadURL().then((url) => {
          this.uploadPercent =  task.snapshot.state;
          this.uploadDocument(url, productId);
          this.image = url;
          // console.log(this.image)
          // this.presentAlertRadio();
          // console.log('DOWNLOAD URL = ', url);
          // this.ps.updatePicture(uid, url);
          // this.picture = url
        })
      })
  }

  changeListenerProduct(event: any, id: string): void {
    // this.presentLoadingWithOptions();
  this.uploadFlag = true;
      // console.log(Date.now());
      const file = event.target.files[0];
      const filePath = 'productImages/' + Date.now() + '.jpg';
      const fileRef = this.afstorage.storage.ref(filePath);
      const task = fileRef.put(file);
      this.uploadPercent = task.snapshot.state;
       //  percentageChanges();
      // // console.log(this.uploadPercent);
      task.then((snap) => {
        snap.ref.getDownloadURL().then((url) => {
          this.uploadPercent =  task.snapshot.state;
          this.uploadDocument(url, id);
          this.image = url;
          // console.log(this.image)
          // this.presentAlertRadio();
          // console.log('DOWNLOAD URL = ', url);
          this.productService.updatePicture(id, url);
          this.image = url
        })
      })
  }

  uploadDocument(url: string, productId: string) {
    // const fileName = this.generateRandomName() + '.jpg';
    const fileRef = this.afstorage.storage.ref().child('images/');
    const uploadTask = fileRef.putString(url);
   //  return this.auths.getSomeone(userID)

    return new Promise((resolve, reject) => {
        uploadTask.on('state_changed', snapshot => {
    },  error => {
          reject(error);
    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        // console.log('teste resolve url' + downloadURL);
        resolve ( {downloadURL});
      });
    });
    });
    // return this.db.list('files');
  }

  setProductStatus(status, id) {
    // console.log(status, id)
     this.productService.updateProductStatus(status, id);
  }
}
