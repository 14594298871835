
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(public afs: AngularFirestore) { }

  async getAllProducts() {
    return await this.afs.collection('products');
  }
  getSingleProduct(id: string) {
    return this.afs.collection('products').doc(id);
  }
  updateProduct(id: string, factory_price: number, retail_price: number, wholesale_price: number ) {
    return this.afs.collection('products').doc(id).update({
      factory_price: factory_price, retail_price: retail_price, wholesale_price: wholesale_price
    });
  }
  updatePicture (id: string, picture: string) {
  return this.afs.collection('products').doc(id).update({'image': picture})
  }

  deleteProduct(id: string) {
    return this.afs.collection('products').doc(id)
  }

  updateProductStatus(status: boolean, id: string) {
    // // console.log(status, id)
    status = !status;
    return this.afs.collection('products').doc(id).update({
      'availability': status});
  }

getPendingSalesStatusFor(id: string) {
  return this.afs.collection('purchases', purchases => purchases.where('PROid','==',id).where('status','==','Pending'))
}

getLoadingSalesStatusFor(id: string) {
  return this.afs.collection('purchases', purchases => purchases.where('PROid','==',id).where('status','==','Loading'))
}

getShippingSalesStatusFor(id: string) {
  return this.afs.collection('purchases', purchases => purchases.where('PROid','==',id).where('status','==','Shipping'))
}
}
