import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { PromoService } from 'src/app/services/promo.service';


@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.scss']
})
export class PromoComponent implements OnInit {

  promos: any;
  singleProduct: any;

  // UPDATE
  name = '';
  subname = '';
  availability = '';
  image = '';
  description = '';
  details = '';
  factory_price: number;
  retail_price: number;
  wholesale_price: number;
  promo: [''];

  public productId: string;
  public picture: string;
  public url: string;
  public promoName: string;

  uploadPercent: any;
  uploadFlag: any;

  constructor( public promoService: PromoService, public afa: AngularFireAuth,
              public afs: AngularFirestore, public afstorage: AngularFireStorage              ) { }

  ngOnInit() {
    this.getAllPromo();
  }

  getAllPromo() {
    this.promoService.getAllPromos().then((res) => {
      res.valueChanges().subscribe( ref => {
        this.promos = ref;
        // console.log(this.prod)
      })
    }) 
  }



  async deleteThePromo(id: string) {
    console.log(id)
    // console.log(id)
   await this.promoService.deletePromo(id);
  }

  
  async addPromo()
  {
    if (this.promoName) {
       await this.promoService.addPromo(this.promoName)
    }
    
  }
  saveChanges(something) {
    
  }
  


  


}
