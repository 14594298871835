import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from '@firebase/app';

// import Cloud Firestore (optional)
import '@firebase/firestore';


@Injectable({
  providedIn: 'root'
})
export class ProductstatusService {

  constructor(private afs: AngularFirestore) { }

  getProductStatus() {
    return this.afs.collection('status');
  }

  getProductPromo(){
    return this.afs.collection('promo');
  }

  updatePromoStatus(userID, orderUID, oldcartdumps, newcartdumps) {
    this.removeCartDump(userID, orderUID, oldcartdumps).then(() => {
      this.addCartDump(userID, orderUID, newcartdumps)
    })
    
}

removeCartDump(userID, orderUID, oldcartdumps) {
  return this.afs.collection('customers')
  .doc(userID)
  .collection('orders')
  .doc(orderUID)
  .update({ cartdumps: firebase.firestore.FieldValue.arrayRemove(oldcartdumps) })
  
}

addCartDump(userID, orderUID, newcartdumps) {
  this.afs.collection('customers')
  .doc(userID)
  .collection('orders')
  .doc(orderUID)
  .update({ cartdumps: firebase.firestore.FieldValue.arrayUnion(newcartdumps) })
}
}

