import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private afs: AngularFirestore) { }

  getAllMessages(receiverID, adminID) {
   return this.afs.collection('customers').doc(receiverID).collection('messages', messages => messages.orderBy('date', 'desc'))
}

addMessage(sender, receiver, message ) {
  const mid = this.afs.createId()
  return this.afs.collection('customers').doc(receiver).collection('messages').doc(mid).set({
      id: mid,
      sender: sender,
      message: message,
      readStatus: "no",
      reciever: receiver,
      date: new Date().getTime()
  
  
});

}
}