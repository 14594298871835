import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class SharesService {

  constructor(public afs: AngularFirestore) { }

  getAllShares() {
    return this.afs.collection('companies');
  }

  getSingleShares(id: string) {
    return this.afs.collection('companies').doc(id);
  }
  updateShares(id: string, name: number, previous: number, current: number ) {
    return this.afs.collection('companies').doc(id).update({
      name: name, previous: previous, current: current
    });
  }
  
  deleteShares(id: string) {
    return this.afs.collection('companies').doc(id)
  }

}

