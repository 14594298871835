
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { PopoverModule, CardsModule, WavesModule, ButtonsModule } from 'ng-uikit-pro-standard';
import { ModalModule, InputsModule } from 'ng-uikit-pro-standard';


import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { environment } from 'src/environments/environment';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { AdmindashboardComponent } from './pages/admindashboard/admindashboard.component';
import { RepdashboardComponent } from './pages/repdashboard/repdashboard.component';
import { ModalComponent } from './modals/modal/modal.component';
import { SalesrepComponent } from './pages/salesrep/salesrep.component';
import { ProductComponent } from './pages/product/product.component';
import { PromoComponent } from './pages/promo/promo.component';
import { AccountantdashboardComponent } from './pages/accountantdashboard/accountantdashboard.component';
import { CashierdashboardComponent } from './pages/cashierdashboard/cashierdashboard.component';
import { LogisticdashboardComponent } from './pages/logisticdashboard/logisticdashboard.component';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { SharesComponent } from './pages/shares/shares.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdmindashboardComponent,
    RepdashboardComponent,
    ModalComponent,
    SalesrepComponent,
    ProductComponent,
    PromoComponent,
    AccountantdashboardComponent,
    CashierdashboardComponent,
    LogisticdashboardComponent,
    SharesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    InputsModule.forRoot(),
    CardsModule.forRoot(),
    ButtonsModule.forRoot(),
    PopoverModule.forRoot(),
    ToastModule.forRoot({ autoDismiss: false, preventDuplicates: true, maxOpened: 20 }),
    MDBBootstrapModulesPro.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFirestoreModule.enablePersistence(),
        AngularFireStorageModule,
        AppRoutingModule,
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: 'Your_api_key'
    })
  ],
  entryComponents: [ ModalComponent ],
  providers: [MDBSpinningPreloader, WavesModule,{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
  schemas:      [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
