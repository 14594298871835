import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class SalesrepService {

  constructor( public afs: AngularFirestore) { }

  getAllSalesRep() {
    return this.afs.collection('Reps')
  }

  getParticularRep(id: string) {
    return this.afs.collection('Reps').doc(id);
  }

  editSalesRep(id: string, name: string) {
    return this.afs.collection('Reps').doc(id).update({
      name: name
    });
  }

  addSalesRep(email: string, jurisdiction: string) {
    const id = this.afs.createId();
    return this.afs.collection('Reps').doc(id).set({
      name: email,
      email: email,
      jurisdiction: jurisdiction,
      label: "",
      value: ""
    });
  }

  removeSalesRep(id: string, name: string) {
    return this.afs.collection('Reps').doc(id).update({
      name: name
    });
  }

}
