import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { PopoverModule, WavesModule, PopoverDirective} from 'ng-uikit-pro-standard';


import * as firebase from 'firebase/app';
import 'firebase/functions';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public email: string;
  public forgotten_email: string;
  public password: string;
  public initialStand: boolean = false;
  public initialOKStand: boolean = true;
  admin: any;
  public adminID: string;
  public loc: string;
  public theToken: any;

  constructor(
    public loginService: LoginService, 
    public router: Router,
    public afa: AngularFireAuth, 
    public afs: AngularFirestore) 
              { 

              }

    signIn(){
            this.afa.auth.signInWithEmailAndPassword(this.email, this.password).then((result) => {
              // console.log(result)
              this.router.navigate(['/admindashboard'])

            }).catch((error) => {
              console.log(error)
              this.showErrorInfo("Email or Password does not match our database");
              


            })
            
            
            
            // .catch(function(error) {
            //     // Handle Errors here.
            //     let errorCode = error.code;
            //     let errorMessage = error.message;
            //     console.log('Email ' + this.email, 'password ' + this.password);
            //     // ...
            //   });


    }




  //  signIn() {
  //    // console.log('im here', this.email, this.password)
     


  //   this.afa.auth.signInWithEmailAndPassword(this.email, this.password).then(()=>{
  //     this.afa.auth.currentUser.getIdTokenResult().then((idTokenResult) => {
  //       // console.log(idTokenResult.claims.user_id)
  //       // console.log(idTokenResult.claims.location)
  //       // console.log(idTokenResult.claims.salesrep)
  //        console.log(idTokenResult)
  //       // console.log(idTokenResult.claims)
        
  //       this.adminID = idTokenResult.claims.user_id;
  //       this.loc = idTokenResult.claims.location
        
  //       if (idTokenResult.claims.accountant) {
  //         this.theToken = 'accountant';
  //       } else if (idTokenResult.claims.logistics) {
  //         this.theToken = 'logistics';
  //       } else if (idTokenResult.claims.cashier) {
  //         this.theToken = 'cashier';
  //       } else if (idTokenResult.claims.admin ) {
  //         this.theToken = 'admin';
  //       } else if (idTokenResult.claims.salesrep ) {
  //         this.theToken = 'salesrep';
  //       } else {
  //         this.theToken = 'none';
  //       }
        
        
  //       if (idTokenResult.claims.admin || idTokenResult.claims.salesrep || idTokenResult.claims.accountant || idTokenResult.claims.cashier || idTokenResult.claims.logistics) {
          
  //         this.router.navigate(['/admindashboard', {adminID: this.adminID, loc: this.loc, theToken: this.theToken}])
  //         // allowAccess();
  //       } 
  //       // else if (idTokenResult.claims.salesrep) {
  //       //   this.router.navigate(['/repdashboard', {adminID: this.adminID, loc: this.loc}])
  //       // } else if (idTokenResult.claims.accountant) {
  //       //   this.router.navigate(['/cashierdashboard', {adminID: this.adminID, loc: this.loc}])
  //       // } else if (idTokenResult.claims.cashier) {
  //       //   this.router.navigate(['/cashierdashboard', {adminID: this.adminID, loc: this.loc}])
  //       // } else if (idTokenResult.claims.logistics) {
  //       //   this.router.navigate(['/cashierdashboard', {adminID: this.adminID, loc: this.loc}])
  //       // }
  //       else {
  //       //   let element: HTMLElement = document.getElementById('examplebut') as HTMLElement;
  //       // element.click();

  //       // let okelement: HTMLElement = document.getElementById('okbut') as HTMLElement;
  //       // okelement.hidden = false;
  //       this.showErrorInfo("You are not an authorised user");
  //       }
  //     }).catch((error) => {
  //       this.showErrorInfo(error);

  //       // let okelement: HTMLElement = document.getElementById('okbut') as HTMLElement;
  //       // okelement.hidden = false;
        
      
        
  //     })
      
      
  //     }).catch((error)=>{
  //       this.showErrorInfo(error);
        
  //     })
  // }
   





  ngOnInit() {
    
    // var addLocation = firebase.functions().httpsCallable('addSalesRepLocation');
    // addLocation({email: 'chris@gudyfoods.com', location: 'lagos C2 and ogun'}).then(result=> {
    //   // console.log(result);
    // }).catch((error)=>{
    //   // console.log(error);
    // })

    // addLocation({email: 'blessing@gudyfoods.com', location: 'lagos A1'}).then(result=> {
    //   // console.log(result);
    // }).catch((error)=>{
    //   // console.log(error);
    // })

    // addLocation({email: 'owolawi.michael@gudyfoods.com', location: 'north'}).then(result=> {
    //   // console.log(result);
    // }).catch((error)=>{
    //   // console.log(error);
    // })

    // addLocation({email: 'moses@gudyfoods.com', location: 'lagos C1'}).then(result=> {
    //   // console.log(result);
    // }).catch((error)=>{
    //   // console.log(error);
    // })

    // addLocation({email: 'olufemi@gudyfoods.com', location: 'south west'}).then(result=> {
    //   // console.log(result);
    // }).catch((error)=>{
    //   // console.log(error);
    // })

    // addLocation({email: 'ayo@gudyfoods.com', location: 'east'}).then(result=> {
    //   // console.log(result);
    // }).catch((error)=>{
    //   // console.log(error);
    // })
  
    // var makeHimCashier = firebase.functions().httpsCallable('addCashier');
    // makeHimCashier({email: 'pauline@gudyfoods.com'}).then(result=> {
    //    console.log(result);
    // }).catch((error)=>{
    //    console.log(error);
    // })

    // var makeHimAcct = firebase.functions().httpsCallable('addAccountant');
    // makeHimAcct({email: 'young@gudyfoods.com'}).then(result=> {
    //    console.log(result);
    // }).catch((error)=>{
    //    console.log(error);
    // })

    // var makeHimLogistics = firebase.functions().httpsCallable('addLogistics');
    // makeHimLogistics({email: 'seun@gudyfoods.com'}).then(result=> {
    //    console.log(result);
    // }).catch((error)=>{
    //    console.log(error);
    // })

    // var makeHimAdmin = firebase.functions().httpsCallable('addAdmin');
    // makeHimAdmin({email: 'williams@gudyfoods.com'}).then(result=> {
    //    console.log(result);
    // }).catch((error)=>{
    //    console.log(error);
    // })


  }

  // makeAdmin(email)
  // {
  //   var makeHimAdmin = firebase.functions().httpsCallable('addAdmin');
  //   makeHimAdmin({email: 'info@spekaasets.com'}).then(result=> {
  //     // console.log(result);
  //   }).catch((error)=>{
  //     // console.log(error);
  //   })
  // }

  

  showErrorInfo(error) {
    
        let errorelement: HTMLElement = document.getElementById('errorParagraph') as HTMLElement;
        let errorbut: HTMLElement = document.getElementById('errorbut') as HTMLElement;
        errorelement.innerHTML = error;
        errorbut.click();
        


        // let incokelement: HTMLElement = document.getElementById('incorrectbut') as HTMLElement;
        // incokelement.hidden = false;
  }


  submitPasswordResetRequest() {
    // let passwordReset: HTMLElement = document.getElementById('passwordSubmitButton') as HTMLElement;
    // let passwordOk: HTMLElement = document.getElementById('passwordOkButton') as HTMLElement;
    let resultParagraph: HTMLElement = document.getElementById('resultParagraph') as HTMLElement;

    

    firebase.auth().sendPasswordResetEmail(this.forgotten_email).then((result) => {
      console.log(result)
      resultParagraph.innerHTML = "Password reset link has been sent into your email, please reset your pass and try log in again!";

    }).catch((error) => {
      console.log(error);
      resultParagraph.innerHTML = error.message;
      // An error happened.
    });

    // click()
    //     let errorbut: HTMLElement = document.getElementById('errorbut') as HTMLElement;
    //     errorelement.innerHTML = error;
    //     errorbut.click();
    


  }

  modalClosed() {
    let resultParagraph: HTMLElement = document.getElementById('resultParagraph') as HTMLElement;
    resultParagraph.innerHTML = "";
  }

}



