import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class OtherLocationsService {

  constructor(public afs: AngularFirestore) { }

  getEditableLocations() {
    return this.afs.collection('other_locations')
  }

  updateCustomerLocation2(id, location) {
    this.afs.collection('customers').doc(id).update({location: location})
  }
}
