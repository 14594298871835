import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AdmindashboardComponent } from './pages/admindashboard/admindashboard.component';
import { RepdashboardComponent } from './pages/repdashboard/repdashboard.component';
import { CashierdashboardComponent } from './pages/cashierdashboard/cashierdashboard.component';
import { AccountantdashboardComponent } from './pages/accountantdashboard/accountantdashboard.component';
import { LogisticdashboardComponent } from './pages/logisticdashboard/logisticdashboard.component';


const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'admindashboard', component: AdmindashboardComponent},
    // { path: 'cashierdashboard', component: CashierdashboardComponent},
    // { path: 'accountantdashboard', component: AccountantdashboardComponent},
    // { path: 'logisticdashboard', component: LogisticdashboardComponent},
    // { path: 'repdashboard', component: RepdashboardComponent},
    {path: '**', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }