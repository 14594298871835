import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  item: Observable<any>;
  repLocation: Observable<any[]>;
  repUsers: Observable<any[]>

  constructor(private afs: AngularFirestore) { }

  async getAllUsers() {
    return await this.afs.collection('customers', ref => ref.orderBy('myDate', 'desc'));

  }

  async getMyUsers(location) {
    
    return this.repUsers = this.afs.collection('customers', customers => customers.where('location', '==', location).orderBy('myDate', 'desc')).valueChanges();
    // console.log(this.repUsers)
  }

  // async  getMyJurisdiction(id) {
    
  //    // console.log(id)
  //    return this.repLocation = this.afs.collection('Reps', reps => reps.where('id','==',id)).valueChanges()
  //    // console.log(this.repLocation)
  // }

  async updateUserId (id: string, accountId: string) {
    return await this.afs.collection('customers').doc(id).update({'customer_id': accountId})
    }

}
