// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyALkRrsW3uDALoGyBNew9z-cRSS1mxA73A",
    authDomain: "spekaassets.firebaseapp.com",
    databaseURL: "https://spekaassets.firebaseio.com",
    projectId: "spekaassets",
    storageBucket: "spekaassets.appspot.com",
    messagingSenderId: "151812241022",
    appId: "1:151812241022:web:db47240be3109ec0838a2f",
    measurementId: "G-5P9Z0DYKBX"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
