import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private afs: AngularFirestore) { }

  getOrderByUser(userID: string) {
    return this.afs.collection('customers').doc(userID).collection('orders', ref => ref.orderBy('date', 'desc'))
  }

  updateUserOrder(userID, orderUID, payDate, payAmount, orderStatus, amountInWords) {
    this.afs.collection('customers').doc(userID).collection('orders').doc(orderUID).update({'status': orderStatus, 'paymentDate': payDate, 'payment': payAmount, 'payment_amount_in_words': amountInWords})
  }

  updateUserOrderStatus(userID, orderUID, orderStatus) {
    return this.afs.collection('customers').doc(userID).collection('orders').doc(orderUID).update({'status': orderStatus})
  }


  addUserRemarkInfo(userID, orderUID, description, qty, remark) {
    const newRemark = {description: description, qty: qty, remark: remark}
    this.afs.collection('customers')
    .doc(userID)
    .collection('orders')
    .doc(orderUID)
    .update({ promo_remark: firebase.firestore.FieldValue.arrayUnion(newRemark) })
  }

  removeUserRemarkInfo(userID, orderUID, description, qty, remark) {
    const newRemark = {description: description, qty: qty, remark: remark}
    this.afs.collection('customers')
    .doc(userID)
    .collection('orders')
    .doc(orderUID)
    .update({ promo_remark: firebase.firestore.FieldValue.arrayRemove(newRemark) })
  }



  updateUserPurchasesStatus(id, purchase) {
     // console.log('i waas called')
// console.log(id, purchase)
      return this.afs.collection('purchases').doc(id).set(purchase);
    
      
     
  }

  updateOrderGrandTotal(customerId, orderId, grandTotal) {
    return this.afs.collection('customers').doc(customerId).collection('orders').doc(orderId).update({total_price: grandTotal})
  }
 
  getPendingOrder() {
    return this.afs.collection('orders', ref => ref.where('status','==', 'Pending').orderBy('date', 'desc'))
  }


  async getPendingOrderSample() {
    return this.afs.collection('orders', ref => ref.where('status','==', 'Pending').orderBy('date', 'desc'))
  }
  

   
   

}
