
import { Component, OnInit } from '@angular/core';
import { SalesrepService } from 'src/app/services/salesrep.service';
import * as firebase from 'firebase/app';
import 'firebase/functions';

@Component({
  selector: 'app-salesrep',
  templateUrl: './salesrep.component.html',
  styleUrls: ['./salesrep.component.scss']
})
export class SalesrepComponent implements OnInit {

  sales: any;
  partRep: any;
  name: string;
  jurisdiction: string;
  public salesRepEmail: string;
  

  constructor(public salesRepService: SalesrepService) { }

  ngOnInit() {
    this.getAllSalesRep();
  }

  getAllSalesRep() {
    this.salesRepService.getAllSalesRep().valueChanges().subscribe(ref => {
      this.sales = ref;
      // console.log(this.sales);
    })
  }

  getParticularRep(id: string) {
    this.salesRepService.getParticularRep(id).valueChanges().subscribe( ref => {
      this.partRep = ref;
      // console.log(this.partRep);
    })
  }

  async editSalesRep(id: string) {
    await this.salesRepService.editSalesRep(id, this.name).then(() => {
      this.name = '', this.jurisdiction = ''
    } )
  }


  // makeSalesRep(email)
  // {
  //   // console.log(this.salesRepEmail);
  //   var makeHimSalesRep = firebase.functions().httpsCallable('addSalesRep');
  //   makeHimSalesRep({email: this.salesRepEmail}).then(result=> {
      
  //     // console.log(result);
  //   }).catch((error)=>{
  //     // console.log(error);
  //   })
  // }

  // unmakeSalesRep(email)
  // {
  //   var makeHimSalesRep = firebase.functions().httpsCallable('removeSalesRep');
  //   makeHimSalesRep({email: this.salesRepEmail}).then(result=> {
      
  //     // console.log(result);
  //   }).catch((error)=>{
  //     // console.log(error);
  //   })
  // }

  

}
