import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shares',
  templateUrl: './shares.component.html',
  styleUrls: ['./shares.component.scss']
})
export class SharesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
