import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class LoginService {



  constructor(public afs: AngularFirestore, public afa: AngularFireAuth) { }

  signIn(email: string, password: string) {
  return this.afa.auth.signInWithEmailAndPassword(email, password)
  }
  signOut() {
   this.afa.auth.signOut();
  }
}